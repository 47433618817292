<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">
                {{ $t('customerInvoiceMsgs.customerInvoices') }}
              </h4>
            </div>

            <div class="text-right">
              <a
                href="#"
                class="btn btn-primary mr-2"
                v-if="
                  [USER_ROLES.ADMIN, USER_ROLES.OFFICE_WORKER, USER_ROLES.PROJECT_MANAGER].includes(
                    getLoggedInUser.role
                  )
                "
                @click.prevent="openExportInvoicesModal()"
              >
                {{ $t('customerInvoiceMsgs.exportInvoices') }}
              </a>
              <router-link :to="{ name: 'add-customer-invoice' }" class="btn btn-primary">{{
                $t('customerInvoiceMsgs.addInvoice')
              }}</router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row mx-1 mb-2 d-flex justify-content-between">
              <h5>{{ $t('generalMsgs.filters') }}</h5>

              <b-button variant="outline-secondary" class="btn-xs" @click="clearFilters" v-if="areFiltersApplied">
                <i class="fas fa-times"></i>
                {{ $t('clearFilters') }}
              </b-button>
            </div>

            <div class="row mb-2">
              <div class="mm-search-bar col-sm-6 col-md-4 mb-2">
                <div class="searchbox">
                  <a class="search-link" href="#">
                    <i class="fas fa-search"></i>
                  </a>
                  <input
                    type="text"
                    class="form-control search-input"
                    v-model="searchTerm"
                    :placeholder="`${$t('customerInvoiceMsgs.searchCustomerInvoices')}...`"
                    @input="onSearch"
                  />
                </div>
              </div>

              <div class="col-sm-6 col-md-4 mb-2">
                <v-select
                  id="customer"
                  class="form-control v-select-custom"
                  style="height: 40px"
                  label="name"
                  v-model="customer"
                  :reduce="(customer) => customer.id"
                  :placeholder="$t('customerMsgs.customer')"
                  :options="allCustomers"
                  :loading="areCustomersLoading"
                  :filterBy="filterCustomers"
                  @input="onFilterApplied"
                >
                  <template #search="{ attributes, events }">
                    <input class="vs__search" v-bind="attributes" v-on="events" />
                  </template>

                  <template slot="option" slot-scope="option">
                    {{ option.name }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </div>

              <div class="col-sm-6 col-md-2 mb-2">
                <b-form-select
                  id="type"
                  class="form-control form-control-b-select"
                  style="height: 40px"
                  v-model="type"
                  :options="allTypeChoices"
                  @input="onFilterApplied"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      {{ $t('type') }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </div>

              <div class="col-sm-6 col-md-2 mb-2">
                <b-form-select
                  id="monthOfFulfillment"
                  class="form-control form-control-b-select"
                  style="height: 40px"
                  v-model="monthOfFulfillment"
                  :options="allMonthsOptions"
                  @input="onFilterApplied"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      {{ $t('customerInvoiceMsgs.fulfillmentMonth') }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="table-responsive">
              <b-table
                :fields="tableColumns"
                :items="allCustomerInvoices"
                :busy="isLoading"
                head-variant="primary"
                hover
                responsive
                no-local-sorting
                @sort-changed="onSortChange"
                @row-clicked="openCustomerInvoiceDetailsModal"
              >
                <template #cell(customer__name)="data">
                  <strong>{{ data.item.customer.name }}</strong>
                </template>

                <template #cell(orders)="data">
                  {{ (data.item.orders || []).map((order) => order.order_number).join(', ') }}
                </template>

                <template #cell(total_invoice_amount)="data">{{
                  $n(data.item.total_invoice_amount, 'currency', 'de-DE')
                }}</template>

                <template #cell(amountWithoutSkonto)="data">{{
                  $n(data.item.amountWithoutSkonto, 'currency', 'de-DE')
                }}</template>

                <template #cell(invoice_date)="data">{{ formatDate(data.item.invoice_date) }}</template>

                <template #cell(month_of_fulfillment)="data">
                  {{ allMonthsOptions.find((month) => month.value === data.item.month_of_fulfillment).text }}
                </template>

                <template #cell(type)="data">
                  {{ $t(`customerInvoiceTypeChoices.${data.item.type}`) }}
                </template>

                <template #cell(paymentStatus)="data">
                  <b-badge
                    :class="`border border-${paymentStatusColors[data.item.payment_status]} text-${
                      paymentStatusColors[data.item.payment_status]
                    }`"
                    variant="none"
                    v-b-popover.hover.top
                    :title="$t(`customerInvoiceMsgs.paymentStatusesDesc.${data.item.payment_status}`)"
                    >{{ $t(`paymentStatusChoices.${data.item.payment_status}`) }}
                  </b-badge>
                </template>

                <template #cell(createdAt)="data">{{ formatDateTime(data.item.created_at) }}</template>

                <template #cell(createdBy)="data">
                  <span v-if="data.item.created_by">
                    {{ data.item.created_by.first_name }}
                    {{ data.item.created_by.last_name }}
                  </span>
                  <span v-else>--</span>
                </template>

                <template #head(actions)>
                  <span></span>
                </template>
                <template #cell(actions)="data" style="min-width: 50px">
                  <router-link
                    :to="{
                      name: 'edit-customer-invoice',
                      params: { id: data.item.id },
                    }"
                    class="svg-icon mr-2"
                    v-b-popover.hover.top
                    :title="$t('edit')"
                  >
                    <i class="far fa-edit text-info" />
                  </router-link>

                  <a
                    href="#"
                    @click.prevent="confirmDeleteCustomerInvoice(data.item.id)"
                    v-b-popover.hover.top
                    :title="$t('remove')"
                  >
                    <i class="far fa-trash-alt text-danger" />
                  </a>
                </template>
              </b-table>

              <b-pagination
                :value="currentPage"
                :total-rows="totalCustomerInvoices"
                :per-page="pageSize"
                @change="onPageChange"
                align="right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <invoices-export-modal :showModal.sync="showExportInvoicesModal" @close="hideExportInvoicesModal" />
    <customer-invoice-detail-modal
      :showModal.sync="modals.showCustomerInvoiceModal"
      :customerInvoiceData="modals.customerInvoiceData"
      @close="hideCustomerInvoicesModal"
      @reset="resetData"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DEFAULT_PAGE_SIZE, USER_ROLES } from '../../common/constants';
import { debounce } from 'lodash';
import vSelect from 'vue-select';
import { formatDate, formatDateTime } from '../../common/utils';
import InvoicesExportModal from './InvoiceExportModal.vue';
import CustomerInvoiceDetailModal from './CustomerInvoiceDetailModal.vue';

export default {
  name: 'CustomerInvoiceList',

  components: { InvoicesExportModal, vSelect, CustomerInvoiceDetailModal },

  data() {
    return {
      allCustomerInvoices: [],
      allCustomers: [],
      currentPage: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      totalCustomerInvoices: 0,

      type: null,
      customer: null,
      monthOfFulfillment: null,

      isLoading: false,
      areCustomersLoading: false,
      searchTerm: '',
      ordering: '',
      showExportInvoicesModal: false,
      USER_ROLES,

      paymentStatusColors: {
        paid: 'success',
        unpaid: 'danger',
      },
      modals: {
        showCustomerInvoiceModal: false,
        customerInvoiceData: null,
      },
    };
  },

  methods: {
    ...mapActions(['getAllCustomerInvoices', 'deleteCustomerInvoice', 'getAllCustomers']),
    formatDate,
    formatDateTime,
    resetData() {
      this.modals.customerInvoiceData = null;
    },
    openCustomerInvoiceDetailsModal(customerInvoiceRow) {
      this.modals.showCustomerInvoiceModal = true;
      this.modals.customerInvoiceData = customerInvoiceRow;
    },

    async fetchAndSetCustomerInvoices(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getAllCustomerInvoices({
        limit: this.pageSize,
        offset: (pageNum - 1) * this.pageSize,
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.type && { type: this.type }),
        ...(this.customer && { customer: this.customer }),
        ...(this.monthOfFulfillment && {
          month_of_fulfillment: this.monthOfFulfillment,
        }),
        ...params,
      });

      response.data.results.forEach((invoice) => {
        invoice['skontoAmount'] = invoice.total_invoice_amount * (invoice.skonto_percent / 100);
        invoice['amountWithoutSkonto'] = invoice.total_invoice_amount - invoice['skontoAmount'];
      });

      const customerInvoices = response.data.results;
      this.allCustomerInvoices = customerInvoices;
      this.currentPage = pageNum;
      this.totalCustomerInvoices = response.data.count;
      this.isLoading = false;
    },

    onFilterApplied() {
      this.fetchAndSetCustomerInvoices();
    },

    filterCustomers(option, _label, search) {
      const lowerSearch = search.toLowerCase();
      return (
        (option.name || '').toLowerCase().includes(lowerSearch) ||
        (option.customer_number || '').toLowerCase().includes(lowerSearch)
      );
    },

    clearFilters() {
      this.searchTerm = '';
      this.customer = this.type = this.monthOfFulfillment = null;
      this.fetchAndSetCustomerInvoices();
    },

    async fetchCustomers(params = {}) {
      this.areCustomersLoading = true;
      const response = await this.getAllCustomers({
        ordering: 'name',
        ...params,
      });
      this.allCustomers = response.data;
      this.areCustomersLoading = false;
    },

    openExportInvoicesModal() {
      this.showExportInvoicesModal = true;
    },
    hideCustomerInvoicesModal() {
      this.modals.showCustomerInvoiceModal = false;
      this.resetData();
    },
    hideExportInvoicesModal() {
      this.showExportInvoicesModal = false;
    },

    onPageChange(pageNum) {
      this.fetchAndSetCustomerInvoices(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchAndSetCustomerInvoices();
    },

    onSearch() {
      this.debouncedSearchCustomerInvoices(this);
    },

    debouncedSearchCustomerInvoices: debounce((vm) => {
      vm.fetchAndSetCustomerInvoices();
    }, 500),

    async confirmDeleteCustomerInvoice(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(this.$t('customerInvoiceMsgs.deleteConfirm'), {
          title: this.$t('generalMsgs.suretyMsg'),
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('generalMsgs.yes'),
          cancelTitle: this.$t('generalMsgs.no'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        });
        if (isConfirmed) {
          await this.deleteCustomerInvoice(id);
          const refreshPage = this.allCustomerInvoices.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchAndSetCustomerInvoices(refreshPage || 1);
          this.makeToast('success', this.$t('customerInvoiceMsgs.customerInvoiceDeleted'));
        }
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {
    this.fetchAndSetCustomerInvoices();
    this.fetchCustomers();
  },

  computed: {
    ...mapGetters(['getLoggedInUser']),
    allMonthsOptions() {
      return [
        { value: 1, text: this.$t('monthNames.jan') },
        { value: 2, text: this.$t('monthNames.feb') },
        { value: 3, text: this.$t('monthNames.march') },
        { value: 4, text: this.$t('monthNames.april') },
        { value: 5, text: this.$t('monthNames.may') },
        { value: 6, text: this.$t('monthNames.june') },
        { value: 7, text: this.$t('monthNames.july') },
        { value: 8, text: this.$t('monthNames.aug') },
        { value: 9, text: this.$t('monthNames.sep') },
        { value: 10, text: this.$t('monthNames.oct') },
        { value: 11, text: this.$t('monthNames.nov') },
        { value: 12, text: this.$t('monthNames.dec') },
      ];
    },

    areFiltersApplied() {
      return this.searchTerm || this.type || this.customer || this.monthOfFulfillment;
    },

    allTypeChoices() {
      return [
        {
          value: 'workshop',
          text: this.$t('customerInvoiceTypeChoices.workshop'),
        },
        {
          value: 'installation',
          text: this.$t('customerInvoiceTypeChoices.installation'),
        },
      ];
    },

    tableColumns() {
      return [
        {
          key: 'customer__name',
          label: this.$t('customerInvoiceMsgs.customerInvoiceCustomerName'),
          sortable: true,
          tdClass: 'clickable-item',
        },
        {
          key: 'orders',
          label: this.$t('orderMsgs.orders'),
          tdClass: 'clickable-item',
        },
        {
          key: 'total_invoice_amount',
          label: this.$t('customerInvoiceMsgs.invoiceAmount'),
          sortable: true,
          tdClass: 'clickable-item',
        },

        {
          key: 'amountWithoutSkonto',
          label: this.$t('customerInvoiceMsgs.amountWithoutSkonto'),
          tdClass: 'clickable-item',
        },
        {
          key: 'invoice_date',
          label: this.$t('customerInvoiceMsgs.customerInvoiceDate'),
          tdClass: 'clickable-item',
          sortable: true,
        },

        {
          key: 'month_of_fulfillment',
          label: this.$t('customerInvoiceMsgs.monthOfFulfillment'),
          tdClass: 'clickable-item',
          sortable: true,
        },
        {
          key: 'type',
          tdClass: 'clickable-item',
          label: this.$t('type'),
        },
        {
          key: 'paymentStatus',
          label: this.$t('customerInvoiceMsgs.paymentStatus'),
          tdClass: 'clickable-item',
        },

        // { key: "createdAt", label: this.$t("addedAt") },
        { key: 'createdBy', label: this.$t('addedBy'), tdClass: 'clickable-item' },

        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '130px' },
        },
      ];
    },
  },
};
</script>
