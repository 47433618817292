<template>
  <a href="#" @click.prevent="downloadGivenFile(fileUrl)"><i class="fas fa-download mr-1" v-if="showDownloadIcon" />{{
    fileName ? fileName : parseFileNameFromUrl(fileUrl, removeTimestamp)
  }}</a>
</template>

<script>
import { mapActions } from "vuex";
import { parseFileNameFromUrl } from "../../common/utils";

export default {
  name: "fileDownloadLink",

  props: {
    fileUrl: { type: String, required: true },
    fileName: { type: String },
    removeTimestamp: { type: Boolean, default: true },
    showDownloadIcon: { type: Boolean, default: false },
  },

  methods: {
    ...mapActions(["downloadFile"]),
    parseFileNameFromUrl,

    async downloadGivenFile(url) {
      const h = this.$createElement;

      this.makeToast(
        "primary",
        h("span", [
          h("i", { class: "fas fa-download mr-2" }),
          this.$t("generalMsgs.downloadingFile"),
        ]),
        "b-toaster-bottom-left"
      );

      await this.downloadFile({
        fileUrl: url,
        removeTimestamp: this.removeTimestamp,
      });

      this.makeToast(
        "success",
        h("span", [
          h("i", { class: "fas fa-download mr-2" }),
          this.$t("generalMsgs.downloadFileSuccess"),
        ]),
        "b-toaster-bottom-left"
      );
    },

    makeToast(variant = null, msg, toaster = undefined) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
        ...(toaster && { toaster }),
      });
    },
  },

  mounted() {},

  computed: {},
};
</script>
