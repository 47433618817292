<template>
  <b-modal
    ref="customer-invoice-detail-modal"
    size="lg"
    :title="$t('customerInvoiceMsgs.customerInvoiceDetail')"
    hide-footer
    centered
    static
    lazy
    @hide="hideModal"
  >
    <!-- <b-skeleton-wrapper :loading="isDataLoading"> -->
    <!-- <template #loading>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
        <b-skeleton width="90%"></b-skeleton>
        <b-skeleton width="65%"></b-skeleton>
        <b-skeleton width="75%"></b-skeleton>
      </template> -->

    <div class="row">
      <div class="col-lg-12" v-if="customerInvoiceData">
        <div class="card card-block card-stretch card-height">
          <div class="card-body">
            <b-row class="mt-3">
              <b-col cols="6" md="2" sm="3" v-if="customerInvoiceData.type">
                <strong>{{ $t('customerInvoiceMsgs.typeKey') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9" v-if="customerInvoiceData.type">
                {{ $t(`customerInvoiceTypeChoices.${customerInvoiceData.type}`) }}
              </b-col>
              <b-col cols="6" md="2" sm="3" v-if="customerInvoiceData.payment_status">
                <strong>{{ $t('customerInvoiceMsgs.statusKey') }}:</strong>
              </b-col>
              <!-- <b-col cols="6" md="4" sm="9" v-if="customerInvoiceData.payment_status">
                {{ $t(`customerInvoiceMsgs.status.${customerInvoiceData.payment_status}`) }}
              </b-col> -->
              <b-badge
                :class="`border border-${paymentStatusColors[customerInvoiceData.payment_status]} text-${
                  paymentStatusColors[customerInvoiceData.payment_status]
                }`"
                variant="none"
                v-b-popover.hover.top
                :title="$t(`customerInvoiceMsgs.paymentStatusesDesc.${customerInvoiceData.payment_status}`)"
                >{{ $t(`paymentStatusChoices.${customerInvoiceData.payment_status}`) }}
              </b-badge>
            </b-row>

            <b-row class="mt-4" v-if="customerInvoiceData.customer.name">
              <b-col cols="12" md="2" sm="3">
                <strong>{{ $t('customerInvoiceMsgs.customerInvoiceCustomerName') }}:</strong>
              </b-col>
              <b-col cols="12" md="6" sm="9">
                {{ customerInvoiceData.customer.name }}
              </b-col>
            </b-row>
            <b-row class="mt-4" v-if="customerInvoiceData.orders.length > 0">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('orderMsgs.orders') }}:</strong>
              </b-col>
              <b-col>{{ (customerInvoiceData.orders || []).map((order) => order.order_number).join(', ') }}</b-col>
            </b-row>
            <b-row class="mt-4" v-if="customerInvoiceData.invoice_number">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('customerInvoiceMsgs.invoiceNum') }}:</strong>
              </b-col>
              <b-col>{{ customerInvoiceData.invoice_number }}</b-col>
            </b-row>

            <b-row class="mt-2" v-if="customerInvoiceData.invoice_date">
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('customerInvoiceMsgs.customerInvoiceDate') }}:</strong>
              </b-col>
              <b-col cols="6" md="10" sm="9">
                {{ formatDate(customerInvoiceData.invoice_date) }}
              </b-col>
            </b-row>

            <b-row></b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3" v-if="customerInvoiceData.due_date">
                <strong>{{ $t('customerInvoiceMsgs.customerDueDate') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9" v-if="customerInvoiceData.due_date">
                {{ formatDate(customerInvoiceData.due_date) }}
              </b-col>

              <b-col cols="6" md="2" sm="3" v-if="customerInvoiceData.skontoAmount">
                <strong>{{ $t('customerInvoiceMsgs.dueDateSkonto') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9" v-if="customerInvoiceData.skontoAmount">
                {{ formatDate(customerInvoiceData.due_date_skonto) }}
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3" v-if="customerInvoiceData.month_of_fulfillment">
                <strong>{{ $t('customerInvoiceMsgs.monthOfFulfillment') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9" v-if="customerInvoiceData.month_of_fulfillment">
                {{ allMonthsOptions.find((month) => month.value === customerInvoiceData.month_of_fulfillment).text }}
              </b-col>
            </b-row>

            <b-row v-if="customerInvoiceData.invoice_file" class="mt-2">
              <b-col cols="12" md="2" sm="3">
                <strong>{{ $t('customerInvoiceMsgs.invoiceFile') }}:</strong>
              </b-col>
              <b-col cols="12" md="6" sm="9">
                <file-download-link :fileUrl="customerInvoiceData.invoice_file" />
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12" md="2" sm="3" v-if="customerInvoiceData.created_by">
                <strong>{{ $t('addedBy') }}:</strong>
              </b-col>
              <b-col cols="12" md="6" sm="9" v-if="customerInvoiceData.created_by">
                {{
                  customerInvoiceData.created_by
                    ? `${customerInvoiceData.created_by.first_name} ${customerInvoiceData.created_by.last_name}`
                    : '-'
                }}
              </b-col>
            </b-row>
            <hr />
            <h4>{{ $t('customerInvoiceMsgs.amount') }}</h4>
            <b-row class="mt-2">
              <b-col cols="6" md="2" sm="3" v-if="customerInvoiceData.total_invoice_amount">
                <strong>{{ $t('customerInvoiceMsgs.invoiceAmount') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9" v-if="customerInvoiceData.total_invoice_amount">
                {{ $n(customerInvoiceData.total_invoice_amount || 0, 'currency', 'de-DE') }}
              </b-col>
              <!-- <b-col cols="6" md="2" sm="3" v-if="customerInvoiceData.skonto_percent">
                <strong>{{ $t('customerInvoiceMsgs.skontoPercent') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9" v-if="customerInvoiceData.skonto_percent">
                {{ $n(customerInvoiceData.skonto_percent || 0, 'currency', 'de-DE') }}
              </b-col> -->
            </b-row>

            <b-row>
              <b-col cols="6" md="2" sm="3" v-if="customerInvoiceData.amountWithoutSkonto">
                <strong>{{ $t('customerInvoiceMsgs.amountWithoutSkonto') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9" v-if="customerInvoiceData.amountWithoutSkonto">
                {{ $n(customerInvoiceData.amountWithoutSkonto || 0, 'currency', 'de-DE') }}
              </b-col>
              <b-col cols="6" md="2" sm="3">
                <strong>{{ $t('customerInvoiceMsgs.skonto') }}:</strong>
              </b-col>
              <b-col cols="6" md="4" sm="9">
                <!-- {{ $n(customerInvoiceData.skontoAmount || 0, 'currency', 'de-DE') }} -->
                {{ $n(customerInvoiceData.skontoAmount, 'currency', 'de-DE') }} ({{
                  $n(customerInvoiceData.skonto_percent, 'number', 'de-DE')
                }}%)
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <!-- </b-skeleton-wrapper> -->
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { formatDate } from '../../common/utils';
import fileDownloadLink from '../../components/files/fileDownloadLink.vue';
//import { get, head } from 'lodash';

export default {
  name: 'CustomerInvoiceDetailModal',

  props: {
    showModal: Boolean,
    customerInvoiceData: Object,
  },
  components: { fileDownloadLink },
  data() {
    return {
      paymentStatusColors: {
        paid: 'success',
        unpaid: 'danger',
      },
    };
  },
  computed: {
    allMonthsOptions() {
      return [
        { value: 1, text: this.$t('monthNames.jan') },
        { value: 2, text: this.$t('monthNames.feb') },
        { value: 3, text: this.$t('monthNames.march') },
        { value: 4, text: this.$t('monthNames.april') },
        { value: 5, text: this.$t('monthNames.may') },
        { value: 6, text: this.$t('monthNames.june') },
        { value: 7, text: this.$t('monthNames.july') },
        { value: 8, text: this.$t('monthNames.aug') },
        { value: 9, text: this.$t('monthNames.sep') },
        { value: 10, text: this.$t('monthNames.oct') },
        { value: 11, text: this.$t('monthNames.nov') },
        { value: 12, text: this.$t('monthNames.dec') },
      ];
    },
  },

  methods: {
    ...mapActions(['getOrder', 'getDeliveryNotes', 'getInvoices', 'getOrdersCosts']),
    formatDate,
    // formatDateTime,
    // resetData() {
    //   this.customerInvoiceData = null;
    //   this.isLoading = false;
    // },

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs['customer-invoice-detail-modal'].hide();
      this.hideModal();
    },
  },

  watch: {
    showModal(value) {
      if (value && this.customerInvoiceData) {
        this.$refs['customer-invoice-detail-modal'].show();
      }
    },
  },
};
</script>