<template>
  <div class="container-fluid">
    <b-modal
      ref="invoice-modal"
      size="lg"
      :title="$t('customerInvoiceMsgs.exportInvoices')"
      hide-footer
      centered
      static
      lazy
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="dateRange">{{ $t('customerInvoiceMsgs.customerInvoiceDate') }}</label>
                  <date-picker
                    id="dateRange"
                    class="form-control datepicker-custom"
                    v-model="dateRange"
                    range
                    :lang="lang"
                    format="DD.MM.YYYY"
                    valueType="YYYY-MM-DD"
                  />
                </div>
              </div>

              <div class="text-right">
                <b-button type="submit" variant="primary" :disabled="isLoading" style="min-width: 100px">
                  <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
                  <span v-else>{{ $t('export') }}</span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/de';

export default {
  name: 'InvoicesExportModal',

  props: {
    showModal: Boolean,
  },

  data() {
    return {
      dateRange: [moment().format('YYYY-MM-01'), moment().format('YYYY-MM-DD')],
      isLoading: false,
    };
  },

  components: { DatePicker },

  methods: {
    ...mapActions(['exportCustomerInvoices', 'downloadFile']),

    resetData() {
      this.dateRange = [moment().format('YYYY-MM-01'), moment().format('YYYY-MM-DD')];
      this.isLoading = false;
    },

    hideModal() {
      this.$emit('close');
      this.resetData();
    },

    hideModalManual() {
      this.$refs['invoice-modal'].hide();
      this.hideModal();
    },

    async onSubmit() {
      this.isLoading = true;

      try {
        const invoiceExportData = {
          start_date: this.dateRange[0],
          end_date: this.dateRange[1],
        };
        const h = this.$createElement;
        const response = await this.exportCustomerInvoices(invoiceExportData);
        this.makeToast('success', this.$t('customerInvoiceMsgs.invoicesExported'));
        this.makeToast(
          'primary',
          h('span', [h('i', { class: 'fas fa-download mr-2' }), this.$t('generalMsgs.downloadingFile')]),
          'b-toaster-bottom-left'
        );
        await this.downloadFile({
          fileUrl: response.data.report_url,
          removeTimestamp: true,
        });
        this.makeToast(
          'success',
          h('span', [h('i', { class: 'fas fa-download mr-2' }), this.$t('generalMsgs.downloadFileSuccess')]),
          'b-toaster-bottom-left'
        );
        this.hideModalManual();
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }

      this.isLoading = false;
    },

    makeToast(variant = null, msg, toaster = undefined) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
        ...(toaster && { toaster }),
      });
    },
  },

  computed: {
    ...mapGetters(['getCurrLanguage']),
    lang() {
      return this.getCurrLanguage;
    },
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs['invoice-modal'].show();
      }
    },
  },
};
</script>
